import { DataModels, ProcessInstance, ServiceUnavaliableError } from '@atlas-engine/atlas_engine_sdk';
import type { Action as NavigationType, Location as RouterLocation } from '@remix-run/router';
import type { NavigateFunction, NavigateOptions, Params, URLSearchParamsInit } from 'react-router-dom';

export type AnyTaskType = (
  | DataModels.FlowNodeInstances.UserTaskInstance
  | DataModels.FlowNodeInstances.ManualTaskInstance
) & { portalMetadata?: Record<string, any> };

enum AdditionalSortColumns {
  processModelName = 'processModelName',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FlowNodeInstanceSortableColumns =
  | DataModels.FlowNodeInstances.FlowNodeInstanceSortableColumns
  | AdditionalSortColumns;
export const FlowNodeInstanceSortableColumns = {
  ...DataModels.FlowNodeInstances.FlowNodeInstanceSortableColumns,
  ...AdditionalSortColumns,
};

export type FlowNodeInstanceSortSettings = {
  sortBy: FlowNodeInstanceSortableColumns;
  sortDir?: SortDirection;
};

export type TaskListWithCorrelationAndInstances = {
  taskList: Array<AnyTaskType>;
  correlation: DataModels.Correlation.Correlation;
  correlationName?: string;
  correlationDescription?: string;
  processInstances: Array<DataModels.ProcessInstances.ProcessInstance>;
};

export type LocationState = {
  action?: 'processStarted' | 'sort';
  loadingSpinnerActive?: boolean;
  progressViewTransition?: boolean;
  correlationId?: string;
  processInstanceId?: string;
  task?: AnyTaskType;
  processInstance?: ProcessInstance;
};

export interface Location extends RouterLocation {
  state: LocationState;
}

declare type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions
) => void;

export type RouteComponentProps<P extends string = string> = {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<P>>;
    navigationType: NavigationType;
    searchParams: [URLSearchParams, SetURLSearchParams];
  };
};

export enum RemoteServiceErrorTypes {
  ECONNREFUSED = 'ECONNREFUSED',
  ECONNRESET = 'ECONNRESET',
  ETIMEDOUT = 'ETIMEDOUT',
}

export function isRemoteConnectionErrorCode(errorCode: string): boolean {
  return (
    errorCode === RemoteServiceErrorTypes.ECONNREFUSED ||
    errorCode === RemoteServiceErrorTypes.ECONNRESET ||
    errorCode === RemoteServiceErrorTypes.ETIMEDOUT
  );
}

export function isConnectionError(error: any): boolean {
  return (
    isRemoteConnectionErrorCode(error.code) ||
    error.message.startsWith('NetworkError') ||
    error.message === 'Failed to fetch' ||
    error.message === 'Network request failed' ||
    error.message === 'Network Error'
  );
}

export class AuthorityUnreachableError extends ServiceUnavaliableError {
  public readonly errorType: RemoteServiceErrorTypes;

  constructor(errorType: RemoteServiceErrorTypes) {
    super('5Minds Authority is unavailable');
    this.errorType = errorType;
  }
}

export class AuthorityUrlNotDefinedError extends Error {
  constructor() {
    super('Authority URL is not defined. Is 5Minds Engine reachable?');
  }
}

export class EngineUnreachableError extends ServiceUnavaliableError {
  public readonly errorType: RemoteServiceErrorTypes;

  constructor(errorType: RemoteServiceErrorTypes) {
    super('5Minds Engine is unavailable');
    this.errorType = errorType;
  }
}
