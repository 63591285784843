import { XIcon } from '@heroicons/react/solid';
import React, { PropsWithChildren, useState } from 'react';

const { Provider, Consumer } = React.createContext<any>({});

export type Variant = 'info' | 'danger' | 'warning' | 'success';

type AlertProps = PropsWithChildren<{
  variant: Variant;
  dismissible?: boolean;
  className?: string;
}>;

type AlertHeaderProps = Pick<AlertProps, 'children' | 'variant'>;
type AlertBodyProps = Pick<AlertProps, 'children' | 'variant'>;
type AlertLinkProps = Pick<AlertProps, 'children' | 'variant'> & {
  href?: string;
  onClick?: (event: any) => void;
};

function getBgColor(variant: Variant): string {
  switch (variant) {
    case 'info':
      return 'bg-blue-100';
    case 'warning':
      return 'bg-yellow-50';
    case 'success':
      return 'bg-green-50';
    case 'danger':
      return 'bg-red-100';

    default:
      throw new Error(`Could not determine background color for Alert Variant\n ${variant}`);
  }
}

function getHeaderColor(variant: Variant): string {
  switch (variant) {
    case 'info':
      return 'text-blue-800';
    case 'warning':
      return 'text-yellow-800';
    case 'success':
      return 'text-green-800';
    case 'danger':
      return 'text-red-800';

    default:
      throw new Error(`Could not determine header color for Alert Variant\n ${variant}`);
  }
}

function getBodyColor(variant: Variant): string {
  switch (variant) {
    case 'info':
      return 'text-blue-700';
    case 'warning':
      return 'text-yellow-700';
    case 'success':
      return 'text-green-700';
    case 'danger':
      return 'text-red-700';

    default:
      throw new Error(`Could not determine body color for Alert Variant\n ${variant}`);
  }
}

function getHoverColor(variant: Variant): string {
  switch (variant) {
    case 'info':
      return 'hover:text-blue-600';
    case 'warning':
      return 'hover:text-yellow-600';
    case 'success':
      return 'hover:text-green-600';
    case 'danger':
      return 'hover:text-red-600';

    default:
      throw new Error(`Could not determine hover color for Alert Variant\n ${variant}`);
  }
}

function getDismissIconColor(variant: Variant): string {
  switch (variant) {
    case 'info':
      return 'bg-blue-100 text-blue-500 hover:bg-blue-200 focus:ring-offset-blue-100 focus:ring-blue-600';
    case 'warning':
      return 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600';
    case 'success':
      return 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600';
    case 'danger':
      return 'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-offset-red-100 focus:ring-red-600';

    default:
      throw new Error(`Could not determine dismiss icon color for Alert Variant\n ${variant}`);
  }
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref): JSX.Element | null => {
  const { variant } = props;
  const bgColor = getBgColor(variant);
  const dismissIconColor = getDismissIconColor(variant);

  const [dismissed, setDismissed] = useState<boolean>(false);

  if (dismissed) {
    return null;
  }

  return (
    <div ref={ref} className={`rounded-md p-4 ${bgColor} ${props.className ?? ''}`}>
      <div className="flex">
        <div className="ml-3 overflow-auto">
          <Provider value={props}>{props.children}</Provider>
        </div>
        {props.dismissible && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={(): void => setDismissed(true)}
                className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${dismissIconColor}`}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
Alert.displayName = 'Alert';

const AlertHeader = React.forwardRef<HTMLDivElement, Partial<AlertHeaderProps>>((props, ref) => {
  return (
    <Consumer>
      {(value): JSX.Element => {
        const headerColor = getHeaderColor(value.variant ?? props.variant);

        return (
          <div ref={ref} className={`text-sm font-medium ${headerColor}`}>
            {props.children}
          </div>
        );
      }}
    </Consumer>
  );
});
AlertHeader.displayName = 'AlertHeader';

const AlertBody = React.forwardRef<HTMLDivElement, Partial<AlertBodyProps>>((props, ref) => {
  return (
    <Consumer>
      {(value): JSX.Element => {
        const bodyColor = getBodyColor(value.variant ?? props.variant);

        return (
          <div ref={ref} className={`text-sm ${bodyColor}`}>
            {props.children}
          </div>
        );
      }}
    </Consumer>
  );
});
AlertBody.displayName = 'AlertBody';

const AlertLink = React.forwardRef<HTMLAnchorElement, Partial<AlertLinkProps>>((props, ref) => {
  return (
    <Consumer>
      {(value): JSX.Element => {
        const textColor = getBodyColor(value.variant ?? props.variant);
        const hoverColor = getHoverColor(value.variant ?? props.variant);

        return (
          <a
            ref={ref}
            className={`font-medium underline ${textColor} ${hoverColor}`}
            href={props.href ?? '#'}
            onClick={props.onClick}
          >
            {props.children}
          </a>
        );
      }}
    </Consumer>
  );
});
AlertLink.displayName = 'AlertLink';

export default Object.assign(Alert, {
  Heading: AlertHeader,
  Body: AlertBody,
  Link: AlertLink,
});
