import { WithTranslation, withTranslation } from 'react-i18next';

import { IAuthService } from '../../../lib';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import ProductInfo from '../../../generatedProductInfo';
import type { LanguageService } from '../../../lib/LanguageService';
import { PortalConfiguration } from '@atlas-engine-contrib/atlas-ui_contracts';

export type UserSettingsViewProps = {
  authService?: IAuthService;
  languageService?: LanguageService;
} & WithTranslation &
  GenericViewProps;

function UserSettingsView(props: UserSettingsViewProps): JSX.Element {
  const currentLanguage = props.languageService?.getCurrentLanguage();
  const languages = props.languageService?.getLanguages();

  return (
    <Layout>
      <LayoutHeader activeNav="user-settings" logo={props.logo} />
      <LayoutContent>
        <div className="user-settings-view">
          <div className="user-settings-view__content">
            <div className="mb-8 bg-[color:var(--user-settings-view-language-box-background-color)] rounded-md shadow-md shadow-[color:var(--shadow-color)] ring-1 ring-[color:rgb(var(--outline-color)/var(--tw-ring-opacity))] ring-opacity-10">
              <div className="py-4 px-6 text-lg">{props.t('UserSettings.HeaderLanguages')}</div>
              <div className="py-4 px-6">
                {languages && (
                  <LanguageSelect
                    languages={languages}
                    currentLanguage={currentLanguage ?? ''}
                    onChange={(newLanguage: string) => props.languageService?.setLanguage(newLanguage)}
                  />
                )}
              </div>
            </div>

            <div className="user-settings-view__version">
              Portal Version {ProductInfo.version.frontend} (packaged with Backend Version {ProductInfo.version.backend}
              )
            </div>
          </div>
        </div>
      </LayoutContent>
    </Layout>
  );
}

type LanguageSelectProps = {
  languages: PortalConfiguration['languages'];
  currentLanguage: string;
  onChange: (newLanguag: string) => void;
};

function LanguageSelect(props: LanguageSelectProps) {
  return (
    <fieldset>
      <div className=" user-settings-view__language-select">
        {props.languages &&
          Object.entries(props.languages).map((language) => {
            const value = language[0];
            const name = language[1];

            return (
              <div key={`${value}_${name}`} className="user-settings-view__language-select-option">
                <input
                  id={`${value}_${name}`}
                  name={name}
                  type="radio"
                  value={value}
                  checked={value === props.currentLanguage}
                  className="user-settings-view__language-select-option-input"
                  onChange={(event) => props.onChange(event.target.value)}
                />
                <label htmlFor={`${value}_${name}`} className="user-settings-view__language-select-option-label">
                  {name}
                </label>
              </div>
            );
          })}
      </div>
    </fieldset>
  );
}

export const UserSettingsViewWithTranslation = withTranslation()(UserSettingsView);
