import { StartDialogConfig } from '@atlas-engine-contrib/atlas-ui_contracts';
import { CursorClickIcon } from '@heroicons/react/outline';

type StartDialogProps = {
  startDialogId: string;
  onStart: (startDialogId: string) => void;
} & StartDialogConfig;

export function StartDialog(props: StartDialogProps): JSX.Element {
  const { title, body, startButtonTitle, onStart, startDialogId } = props;

  const safeStartDialogId = startDialogId.trim().replaceAll(' ', '-');

  return (
    <li className={`startable startable--start-dialog start-dialog start-dialog--${safeStartDialogId}`}>
      <button type="button" onClick={(): void => onStart(startDialogId)} className="start-dialog__start-button">
        <span className="min-w-0 flex-1 flex items-center space-x-3">
          <span className="block flex-shrink-0"></span>
          <span className="block min-w-0 flex-1">
            <span className="startable__title" title={title}>
              {title}
            </span>
            <span className="startable__body" title={body}>
              {body}
            </span>
            <span className="startable__start-event" title={startButtonTitle}>
              {startButtonTitle}
            </span>
          </span>
        </span>
        <span className="flex-shrink-0 h-10 w-auto inline-flex items-center justify-center">
          <CursorClickIcon className="h-6" />
        </span>
      </button>
    </li>
  );
}
