import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Base64 } from 'js-base64';

import { EngineService, useLocation } from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';

export type StartProcessViaUrlViewProps = GenericViewProps & {
  engineService: EngineService;
};

type StartProcessViaUrlParameters = {
  processModelId: string;
  '*': any;
};

export function StartProcessViaUrlView(props: StartProcessViaUrlViewProps): JSX.Element {
  const { processModelId, '*': encodedPayload } = useParams<StartProcessViaUrlParameters>();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const showLoadingSpinnerOnInitialRender =
    state?.loadingSpinnerActive === true || props.loadingSpinnerActiveOnInitialAccess;

  const startEventId = searchParams.get('startEventId') ?? undefined;
  const correlationId = searchParams.get('correlationId') ?? undefined;

  const navigate = useNavigate();
  const [lastError, setLastError] = useState<Error | null>(null);
  const loadingSpinnerRef = useRef();

  useEffect(() => {
    if (!processModelId) {
      return;
    }

    let payload;
    if (encodedPayload) {
      try {
        payload = JSON.parse(Base64.decode(encodedPayload));
      } catch (error) {
        setLastError(error);
        return;
      }
    }
    props.engineService
      .startProcessInstance(processModelId, payload, startEventId, correlationId)
      .then((result) => {
        navigate(`/correlation/${result.correlationId}`, {
          replace: true,
          state: {
            loadingSpinnerActive: loadingSpinnerRef.current != null,
            processInstanceId: result.processInstanceId,
            action: 'processStarted',
            correlationId: result.correlationId,
          },
        });
      })
      .catch((error) => {
        setLastError(error);
      });
  }, [processModelId, encodedPayload, props.engineService]);

  return (
    <Layout id="start-process-via-url-view">
      <LayoutHeader logo={props.logo} />
      {!lastError && (
        <DelayedRenderer timeoutInMs={showLoadingSpinnerOnInitialRender ? 0 : undefined}>
          <LoadingSpinner htmlRef={loadingSpinnerRef} style={{ gridArea: 'content' }} />
        </DelayedRenderer>
      )}
      <LayoutContent>{lastError && <ErrorRenderer error={lastError} />}</LayoutContent>
    </Layout>
  );
}
