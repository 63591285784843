import { Fragment, useEffect } from 'react';
import { Link, useNavigate, useNavigationType } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { classNames, TaskListWithCorrelationAndInstances, useLocation } from '../../../lib/index';

type TasksByCorrelationGroupProps = TaskListWithCorrelationAndInstances;

export function TasksByCorrelationGroup(props: TasksByCorrelationGroupProps): JSX.Element {
  const { t } = useTranslation();
  const { taskList: tasks, correlationDescription, correlation } = props;
  const correlationName = props.correlationName ?? t('TaskList.CorrelationNameUnknown');
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  useEffect(() => {
    const hash = location.hash;
    if (hash && location.state?.action !== 'sort') {
      const element = document.querySelector(hash);

      setTimeout(() => {
        element?.scrollIntoView({
          behavior: 'smooth',
          block: navigationType === 'REPLACE' ? 'nearest' : 'center',
        });
      }, 16);
    }
  }, [location.hash, navigationType]);

  return (
    <Fragment>
      {tasks.map((task) => {
        const { portalMetadata } = task;

        return (
          <tr
            key={task.flowNodeInstanceId}
            id={`task-${task.flowNodeInstanceId}`}
            className={location.hash === `#task-${task.flowNodeInstanceId}` ? 'bg-gray-200' : ''}
            onClick={() =>
              navigate(
                {
                  hash: `#task-${task.flowNodeInstanceId}`,
                  search: location.search,
                },
                { replace: true }
              )
            }
          >
            <td
              className={classNames(
                portalMetadata?.seen === true
                  ? ''
                  : 'border-l-4 border-[color:var(--task-list-unseen-task-indicator-color)]',
                'sm:whitespace-nowrap text-[color:var(--text-brightest-color)]'
              )}
              title={task.startedAt?.toISOString()}
            >{`${task.startedAt?.toLocaleDateString(undefined, {
              dateStyle: 'medium',
            })} ${task.startedAt?.toLocaleTimeString()}`}</td>

            <td className="font-medium text-[color:var(--text-color)] truncate max-w-[12rem] sm:max-w-[16rem] md:max-w-xs">
              <div className="flex items-center">
                <div className="truncate">
                  <span className="block text-[color:var(--text-color)] truncate" title={correlationName}>
                    {correlationName}
                  </span>
                  <span
                    className="block text-[color:var(--text-brightest-color)] truncate"
                    title={correlationDescription}
                  >
                    {correlationDescription}
                  </span>
                </div>
              </div>
            </td>

            <td className="text-[color:var(--text-brightest-color)]" title={task.flowNodeName}>
              {task.flowNodeName ?? t('TaskList.NoTaskName')}
            </td>
            <td className="text-right font-medium">
              <Link
                onClick={(event) => {
                  setTimeout(() => {
                    navigate((event.target as HTMLAnchorElement).pathname);
                  }, 0);
                }}
                to={{
                  pathname: `/task/${correlation.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`,
                }}
                state={{
                  task: task,
                  processInstance: props.processInstances.find(
                    (instance) => instance.processInstanceId === task.processInstanceId
                  ),
                }}
                className="text-[color:var(--link-color)] hover:text-[color:var(--link-hover-color)] hover:cursor-pointer"
              >
                {t('TaskList.Continue')}
                <span className="sr-only">, {task.flowNodeName ?? t('TaskList.NoTaskName')}</span>
              </Link>
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
}
