import { EventEmitter } from 'events';

export type AbstractSubscription = {
  dispose: () => void;
};
export type AbstractListener = (...args: Array<any>) => void;

export abstract class AbstractEmitter {
  private eventEmitter: EventEmitter = new EventEmitter();

  public on(eventName: string, listener: AbstractListener): AbstractSubscription {
    this.eventEmitter.on(eventName, listener);

    return this.createSubscription(eventName, listener);
  }

  public once(eventName: string, listener: AbstractListener): AbstractSubscription {
    this.eventEmitter.once(eventName, listener);

    return this.createSubscription(eventName, listener);
  }

  protected emit(eventName: string, args: Array<any> = []): void {
    this.eventEmitter.emit(eventName, ...args);
  }

  protected getListenerCount(eventName: string): number {
    return this.eventEmitter.listenerCount(eventName);
  }

  private createSubscription(eventName: string, listener: AbstractListener): AbstractSubscription {
    return {
      dispose: () => this.eventEmitter.off(eventName, listener),
    };
  }
}

type BufferedEvent = {
  name: string;
  args: any[];
};

export abstract class AbstractEmitterWithInitialBuffer extends AbstractEmitter {
  private isBuffered = true;
  private bufferedEvents: BufferedEvent[] = [];

  protected emit(eventName: string, args: any[] = []): void {
    if (this.isBuffered) {
      this.bufferedEvents.push({ name: eventName, args: args });
    } else {
      super.emit(eventName, args);
    }
  }

  /**
   * Flushes all buffered events as if they were emitted just now and stops buffering events afterwards.
   */
  releaseEventBuffer(): void {
    this.bufferedEvents.forEach(({ name, args }) => super.emit(name, args));
    this.bufferedEvents = [];
    this.isBuffered = false;
  }
}
