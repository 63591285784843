import { CursorClickIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';

type ProcessModelProps = {
  id: string;
  title: string;
  body?: string;
  startButtonTitles: { [startEventId: string]: string };
  onStart: (processId: string, startEventId?: string) => void;
};

export function ProcessModel(props: ProcessModelProps): JSX.Element {
  const { id, title, body, startButtonTitles, onStart } = props;

  const safeId = id.trim().replaceAll(' ', '-');

  return (
    <Fragment>
      {Object.keys(startButtonTitles).map((startEventId) => (
        <li
          key={`${id}-${startEventId}`}
          className={`startable startable--process-model process-model process-model--${safeId}`}
        >
          <button
            type="button"
            onClick={(): void => onStart(id, startEventId)}
            className={`process-model__start-button process-model__start-button--${startEventId}`}
          >
            <span className="min-w-0 flex-1 flex items-center space-x-4">
              <span className="block flex-shrink-0"></span>
              <span className="block min-w-0 flex-1">
                <span className="startable__title" title={title}>
                  {title}
                </span>
                <span className="startable__body" title={body}>
                  {body}
                </span>
                <span className="startable__start-event" title={startButtonTitles[startEventId]}>
                  {startButtonTitles[startEventId]}
                </span>
              </span>
            </span>
            <span className="flex-shrink-0 h-10 w-auto inline-flex items-center justify-center mr-4">
              <CursorClickIcon className="h-6" />
            </span>
          </button>
        </li>
      ))}
    </Fragment>
  );
}
