import { PropsWithChildren } from 'react';

import { IAuthService, useLocation } from '../lib/index';
import { GenericViewProps } from './GenericViewProps';
import { LoginPromptView } from './views/login-prompt-view/LoginPromptView';

export type PrivateRouteProps = GenericViewProps & {
  authService?: IAuthService;
};

export function PrivateRoute(props: PropsWithChildren<PrivateRouteProps>): JSX.Element | null {
  const location = useLocation();

  if (props.authService?.isLoggedIn()) {
    return (props.children as JSX.Element) ?? null;
  }

  if (!props.authService) {
    return null;
  }

  return (
    <LoginPromptView
      loadingSpinnerActiveOnInitialAccess={props.loadingSpinnerActiveOnInitialAccess}
      logo={props.logo}
      authService={props.authService}
      redirectAfterLogin={location}
    />
  );
}
