import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

const NewTasksContext = createContext({
  newTasksAvailable: false,
  setNewTasksAvailable: (value: boolean): void => {},
});

export function useNewTasksContext() {
  return useContext(NewTasksContext);
}

export function NewTasksProvider(props: PropsWithChildren<any>): JSX.Element {
  const [newTasksAvailable, setNewTasksAvailable] = useState<boolean>(false);

  const newTasksContextValue = useMemo(() => {
    return {
      newTasksAvailable: newTasksAvailable,
      setNewTasksAvailable: setNewTasksAvailable,
    };
  }, [newTasksAvailable, setNewTasksAvailable]);

  return <NewTasksContext.Provider value={newTasksContextValue}>{props.children}</NewTasksContext.Provider>;
}
