import { Component, createRef, Fragment } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';

import {
  ManualTaskAbortedEvent,
  ManualTaskFinishedEvent,
  ManualTaskSuspendedEvent,
  ProcessInstanceTerminateEvent,
  ProcessInstanceTerminatedEvent,
  TaskViewConfig,
  UserTaskAbortedEvent,
  UserTaskFinishedEvent,
  UserTaskSuspendedEvent,
  CustomFormTranscendentErrorEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';
import { UserInteractionComponent } from '@atlas-engine-contrib/atlas-ui_user-interaction-component';
import { BpmnType } from '@atlas-engine/atlas_engine_sdk';

import {
  AnyTaskType,
  createComponentStatePersistenceService,
  EngineService,
  IdentityWithEmailAndName,
} from '../../../lib/index';
import { ErrorRenderer } from '../../components/ErrorRenderer';

import { WithIdentity, withIdentity } from '../../context';
import type { LanguageService } from '../../../lib/LanguageService';

type TaskViewState = {
  error?: Error;
  showConfirmTerminationModal: boolean;
  taskIsFinished: boolean;
};

type TaskViewProps = {
  task?: AnyTaskType;
  engineService: EngineService;
  taskViewConfig: TaskViewConfig;
  languageService: LanguageService;
  onReady: () => void;
  onTaskFinished: (event: ManualTaskFinishedEvent | UserTaskFinishedEvent, task: AnyTaskType) => void;
  onTaskSuspended: (
    event: ManualTaskSuspendedEvent | UserTaskSuspendedEvent | ManualTaskAbortedEvent | UserTaskAbortedEvent,
    task: AnyTaskType
  ) => void;
  onProcessInstanceTerminated: (event: ProcessInstanceTerminatedEvent, task: AnyTaskType) => void;
  onBusy: () => void;
} & WithTranslation &
  WithIdentity;

class TaskViewComponent extends Component<TaskViewProps, TaskViewState> {
  public state: TaskViewState = {
    taskIsFinished: false,
    showConfirmTerminationModal: false,
  };

  private handleTaskSuspendedBound = this.handleTaskSuspended.bind(this);
  private handleTaskFinishedBound = this.handleTaskFinished.bind(this);
  private handleOnProcessInstanceTerminateBound = this.handleOnProcessInstanceTerminate.bind(this);
  private handleOnProcessInstanceTerminatedBound = this.handleOnProcessInstanceTerminated.bind(this);

  private uicEventListenerMap = new Map<string, (event: any) => void>([
    [ManualTaskSuspendedEvent.type, this.handleTaskSuspendedBound],
    [UserTaskSuspendedEvent.type, this.handleTaskSuspendedBound],
    [ManualTaskFinishedEvent.type, this.handleTaskFinishedBound],
    [UserTaskFinishedEvent.type, this.handleTaskFinishedBound],
    [ManualTaskAbortedEvent.type, this.handleTaskSuspendedBound],
    [UserTaskAbortedEvent.type, this.handleTaskSuspendedBound],
    [ProcessInstanceTerminateEvent.type, this.handleOnProcessInstanceTerminateBound],
    [ProcessInstanceTerminatedEvent.type, this.handleOnProcessInstanceTerminatedBound],
    [CustomFormTranscendentErrorEvent.type, this.handleCustomFormTranscendentError],
    ['busy', this.props.onBusy],
  ]);

  private userInteractionComponent = createRef<UserInteractionComponent>();
  private cancelTerminationModalButtonRef = createRef<HTMLButtonElement>();
  private resolveConfirmTerminationModal: (result: 'confirm' | 'cancel') => void = () => void 0;

  public async componentDidMount(): Promise<void> {
    if (this.props.task?.flowNodeType === BpmnType.userTask) {
      try {
        await this.props.engineService.reserveUserTask(this.props.task?.flowNodeInstanceId);
      } catch (error) {
        this.setState({ error: error });
      }
    }
    this.handleUserInteractionComponentMount();
  }

  public async componentDidUpdate(prevProps: TaskViewProps, prevState: TaskViewState): Promise<void> {
    if (this.props.task?.flowNodeInstanceId !== prevProps.task?.flowNodeInstanceId) {
      if (prevProps.task?.flowNodeType === BpmnType.userTask && !prevState.taskIsFinished) {
        await this.props.engineService.cancelUserTaskReservation(prevProps.task.flowNodeInstanceId);
      }
      if (this.props.task?.flowNodeType === BpmnType.userTask) {
        await this.props.engineService.reserveUserTask(this.props.task.flowNodeInstanceId);
      }

      if (this.props.task == null) {
        this.handleUserInteractionComponentUnmount();
      } else {
        await this.handleUserInteractionComponentMount();
      }
    }
    if (this.props.identity?.token !== prevProps.identity?.token) {
      this.handleIdentityChanged(this.props.identity);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    if (this.props.task && this.props.task.flowNodeType === BpmnType.userTask && !this.state.taskIsFinished) {
      await this.props.engineService.cancelUserTaskReservation(this.props.task.flowNodeInstanceId);
    }

    this.handleUserInteractionComponentUnmount();
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.state.error && (
          <div className="mb-4">
            <ErrorRenderer error={this.state.error} />
          </div>
        )}
        <Transition.Root show={this.state.showConfirmTerminationModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-30 inset-0 overflow-y-auto"
            initialFocus={this.cancelTerminationModalButtonRef}
            onClose={(): void => this.resolveConfirmTerminationModal('cancel')}
          >
            <div className="task-view-termination-modal">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="task-view-termination-modal__overlay" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="task-view-termination-modal__content">
                  <div className="task-view-termination-modal__content-body">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[color:var(--task-view-termination-modal-body-background-color)] rounded-md text-[color:var(--task-view-termination-modal-close-icon-text-color)] hover:text-[color:var(--task-view-termination-modal-close-icon-text-hover-color)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[color:var(--task-view-termination-modal-body-background-color)] focus:ring-[color:var(--focus-color)]"
                        onClick={(): void => this.resolveConfirmTerminationModal('cancel')}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[color:var(--text-color)]">
                          {this.props.t('TaskView.TerminationModal.Title')}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-[color:var(--text-brightest-color)]">
                            <Trans
                              i18nKey="TaskView.TerminationModal.Body"
                              components={{
                                br: <br />,
                                b: <b />,
                              }}
                            ></Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="task-view-termination-modal__content-footer">
                    <button
                      type="button"
                      className="task-view-termination-modal__content-footer__button task-view-termination-modal__content-footer__button--confirm"
                      onClick={(): void => this.resolveConfirmTerminationModal('confirm')}
                    >
                      {this.props.t('TaskView.TerminationModal.ConfirmButton')}
                    </button>
                    <button
                      type="button"
                      className="task-view-termination-modal__content-footer__button task-view-termination-modal__content-footer__button--cancel"
                      onClick={(): void => this.resolveConfirmTerminationModal('cancel')}
                      ref={this.cancelTerminationModalButtonRef}
                    >
                      {this.props.t('TaskView.TerminationModal.CancelButton')}
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <user-interaction-component ref={this.userInteractionComponent} style={{ height: '100%' }} />
      </Fragment>
    );
  }

  private async handleUserInteractionComponentMount(): Promise<void> {
    const { task } = this.props;
    const { identity } = this.props;
    const userInteractionComponent = this.userInteractionComponent.current;

    if (!task || !identity || !task?.flowNodeInstanceId || !userInteractionComponent) {
      return;
    }

    this.handleUserInteractionComponentUnmount();
    userInteractionComponent.identity = identity;
    userInteractionComponent.customFormConfig = {};
    userInteractionComponent.engineBaseUrl = this.props.engineService.getEngineBaseUrl();
    userInteractionComponent.disableAutoWait = true;
    userInteractionComponent.customFormResolver = this.props.taskViewConfig.resolveCustomForm;
    userInteractionComponent.formStatePersistentService = createComponentStatePersistenceService(
      this.props.engineService
    );
    userInteractionComponent.showTerminateButtonIfUserHasClaim = true;
    userInteractionComponent.languageService = this.props.languageService;

    this.uicEventListenerMap.forEach((listener, type) => userInteractionComponent.addEventListener(type, listener));

    if (task.flowNodeType === BpmnType.userTask) {
      await userInteractionComponent.displayUserTask(task.flowNodeInstanceId);
    } else if (task.flowNodeType === BpmnType.manualTask) {
      await userInteractionComponent.displayManualTask(task.flowNodeInstanceId);
    } else {
      throw new Error('Unknown task type.');
    }

    this.props.onReady();
  }

  private handleUserInteractionComponentUnmount(): void {
    const userInteractionComponent = this.userInteractionComponent.current;
    if (!userInteractionComponent) {
      return;
    }

    this.uicEventListenerMap.forEach((listener, type) => userInteractionComponent.removeEventListener(type, listener));
  }

  private handleIdentityChanged(newIdentity: IdentityWithEmailAndName | null): void {
    const userInteractionComponent = this.userInteractionComponent.current;

    if (userInteractionComponent != null) {
      userInteractionComponent.identity = newIdentity;
    }
  }

  private async handleOnProcessInstanceTerminate(event: ProcessInstanceTerminateEvent): Promise<void> {
    event.preventDefault();

    this.setState({ showConfirmTerminationModal: true });

    const result = await new Promise((resolve: (result: 'confirm' | 'cancel') => void) => {
      this.resolveConfirmTerminationModal = resolve;
    });

    this.setState({ showConfirmTerminationModal: false });

    if (result === 'cancel') {
      event.abort = true;
      return;
    }

    if (this.props.task != null) {
      try {
        this.props.onBusy();
        await this.props.engineService.terminateProcessInstance(this.props.task.processInstanceId);
      } catch (error) {
        event.error = error;
        this.setState({ error: error });
      }
    }
  }

  private async handleOnProcessInstanceTerminated(event: ProcessInstanceTerminatedEvent): Promise<void> {
    this.setState({
      taskIsFinished: true,
    });

    this.props.onProcessInstanceTerminated(event, this.props.task!);
  }

  private handleTaskFinished(event: ManualTaskFinishedEvent | UserTaskFinishedEvent): void {
    this.setState({
      taskIsFinished: true,
    });

    this.props.onTaskFinished(event, this.props.task!);
  }

  private handleTaskSuspended(
    event: ManualTaskSuspendedEvent | UserTaskSuspendedEvent | ManualTaskAbortedEvent | UserTaskAbortedEvent
  ): void {
    this.props.onTaskSuspended(event, this.props.task!);
  }

  private handleCustomFormTranscendentError(event: CustomFormTranscendentErrorEvent): void {
    window.dispatchEvent(event.errorOrUnhandledRejectionEvent);
  }
}

export const TaskView = withTranslation()(withIdentity(TaskViewComponent));
