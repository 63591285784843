import React from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom';
import { Action, BrowserHistory, Location, createBrowserHistory } from '@remix-run/router';

type BrowserRouterState = {
  action: Action;
  location: Location;
};

export const BrowserRouter: React.FC<BrowserRouterProps & { onFirstNavigate?: () => void }> = (
  props: BrowserRouterProps & { onFirstNavigate?: () => void }
) => {
  const historyRef = React.useRef<BrowserHistory>();

  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({
      window: props.window,
      v5Compat: true,
    });
  }

  const history = historyRef.current;

  const [firstNavigateCallback, setFirstNavigateCallback] = React.useState({
    cb: props.onFirstNavigate,
  });
  const [state, setState] = React.useState<BrowserRouterState>({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(
    () =>
      history.listen((update) => {
        if (typeof firstNavigateCallback.cb === 'function') {
          firstNavigateCallback.cb();
          setFirstNavigateCallback({ cb: undefined });
        }
        setState(update);
      }),
    [history, firstNavigateCallback]
  );

  return (
    <Router
      basename={props.basename}
      children={props.children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
