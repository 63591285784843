import { PropsWithChildren, createContext, useContext } from 'react';
import { TaskService } from '../../lib';

type TaskServiceProviderProps = {
  taskService?: TaskService;
};

const TaskServiceContext = createContext<TaskService | undefined>(undefined);

export function TaskServiceProvider(props: PropsWithChildren<TaskServiceProviderProps>): JSX.Element {
  return <TaskServiceContext.Provider value={props.taskService}>{props.children}</TaskServiceContext.Provider>;
}

export function useTaskService(): TaskService | undefined {
  return useContext(TaskServiceContext);
}

export type WithTaskService = TaskServiceProviderProps;

export function withTaskService<TProps extends WithTaskService>(Component: React.ComponentType<TProps>) {
  const ComponentWithTaskService = (props: Omit<TProps, keyof WithTaskService>) => {
    const taskService = useTaskService();

    // Unfortunately, the type assertion is necessary due to a likely bug in TypeScript
    // https://github.com/Microsoft/TypeScript/issues/28938
    return <Component {...(props as TProps)} taskService={taskService} />;
  };

  const componentName = Component.displayName ?? Component.name ?? 'Component';
  ComponentWithTaskService.displayName = `WithTaskService(${componentName})`;

  return ComponentWithTaskService;
}
