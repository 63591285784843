import type { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';
import { AbstractEmitterWithInitialBuffer, AbstractListener, AbstractSubscription } from './AbstractEmitter';
import { EngineService } from './EngineService';
import {
  AnyTaskType,
  FlowNodeInstanceSortableColumns,
  FlowNodeInstanceSortSettings,
  SortDirection,
} from './InternalTypes';

export const EVENT_NEW_TASKS = 'EVENT_NEW_TASKS';
export const EVENT_TASKS_UPDATED = 'EVENT_TASKS_UPDATED';

type TaskServiceEventName = typeof EVENT_NEW_TASKS | typeof EVENT_TASKS_UPDATED;

export class TaskService extends AbstractEmitterWithInitialBuffer {
  private readonly engineService: EngineService;
  private tasks: AnyTaskType[] = [];
  private flowNodeInstanceIds: string[] = [];
  private sortSettings?: FlowNodeInstanceSortSettings;
  private lastSortSettings?: FlowNodeInstanceSortSettings;

  private errorOnUpdateTasks?: Error;
  private resolveGetTasks: ((value: AnyTaskType[] | PromiseLike<AnyTaskType[]>) => void)[] = [];
  private rejectGetTasks: ((reason?: any) => void)[] = [];

  private requestInProgress = false;
  private requestRequired = false;
  private requestRequiredParams: any[] = [];
  private requestTimeout?: number;

  private isInitialized = false;
  private taskSubscriptions?: Subscription[];

  constructor(engineService: EngineService) {
    super();

    this.engineService = engineService;

    const searchParams = new URLSearchParams(window.location.search);
    const sortBy = (searchParams.get('sortBy') ??
      FlowNodeInstanceSortableColumns.createdAt) as FlowNodeInstanceSortableColumns;
    const sortDir = (searchParams.get('sortDir') ?? SortDirection.DESC) as SortDirection;

    this.sortSettings = { sortBy, sortDir };
    this.lastSortSettings = this.sortSettings;

    this.engineService
      .onTaskOrTaskRelatedNotification(this.updateTasksIfNecessary.bind(this))
      .then((subscription) => {
        this.taskSubscriptions = subscription;
      })
      .catch((error) => console.warn(error));
    this.updateTasksIfNecessary();
  }

  public on(eventName: TaskServiceEventName, listener: AbstractListener): AbstractSubscription {
    return super.on(eventName, listener);
  }

  public getTasks(): Promise<AnyTaskType[]> {
    if (!this.taskSubscriptions) {
      this.engineService
        .onTaskOrTaskRelatedNotification(this.updateTasksIfNecessary.bind(this))
        .then((subscription) => {
          this.taskSubscriptions = subscription;
        })
        .catch((error) => console.warn(error));
    }
    if (!this.isInitialized && this.requestInProgress) {
      return new Promise((resolve, reject) => {
        this.resolveGetTasks.push(resolve);
        this.rejectGetTasks.push(reject);
      });
    }

    if (this.errorOnUpdateTasks) {
      return new Promise((resolve, reject) => {
        this.resolveGetTasks.push(resolve);
        this.rejectGetTasks.push(reject);

        this.updateTasksIfNecessary();
      });
    }

    return Promise.resolve(this.tasks);
  }

  public sortTasks(sortSettings?: FlowNodeInstanceSortSettings): void {
    const settingsNotEqual =
      Object.entries(sortSettings ?? {})
        .sort()
        .toString() !==
      Object.entries(this.sortSettings ?? {})
        .sort()
        .toString();

    if (settingsNotEqual) {
      this.lastSortSettings = this.sortSettings;
      this.sortSettings = sortSettings;

      this.updateTasksIfNecessary();
    }
  }

  private async updateTasksIfNecessary(eventType?: string, eventPayload?: any): Promise<void> {
    if (eventType === 'onProcessEnded' || eventType === 'onProcessTerminated' || eventType === 'onProcessError') {
      const processInstanceId = eventPayload?.processInstanceId;
      const noTasksForProcessInstance = !this.tasks.some((task) => task.processInstanceId === processInstanceId);

      if (noTasksForProcessInstance) {
        return;
      }
    }

    if (this.requestInProgress) {
      this.requestRequired = true;
      this.requestRequiredParams = [eventType, eventPayload];
      return;
    }
    this.requestInProgress = true;
    try {
      const sortSettingsToUse =
        // The `processModelName` is currently not supported by the engine. See https://github.com/atlas-engine/AtlasEngine/issues/1300
        this.sortSettings?.sortBy === FlowNodeInstanceSortableColumns.processModelName
          ? undefined
          : (this.sortSettings as DataModels.FlowNodeInstances.FlowNodeInstanceSortSettings);

      if (
        sortSettingsToUse &&
        (!(sortSettingsToUse?.sortBy! in FlowNodeInstanceSortableColumns) ||
          !(sortSettingsToUse?.sortDir! in SortDirection))
      ) {
        throw new Error(
          `sortBy \`${sortSettingsToUse?.sortBy}\` is not a sortable column or sortDir \`${sortSettingsToUse?.sortDir}\` is not a sortable direction`
        );
      }

      const tasks = await this.engineService.getTasks(sortSettingsToUse);
      const newFlowNodeInstanceIds = tasks.map((task) => task.flowNodeInstanceId);

      if (arrayEquals(newFlowNodeInstanceIds, this.flowNodeInstanceIds)) {
        if (
          this.sortSettings?.sortBy === FlowNodeInstanceSortableColumns.processModelName ||
          this.lastSortSettings?.sortBy === FlowNodeInstanceSortableColumns.processModelName
        ) {
          this.emit(EVENT_TASKS_UPDATED);
        }

        this.resolveGetTasksPromises();

        return;
      }

      this.tasks = tasks;
      this.isInitialized = true;
      this.emit(EVENT_TASKS_UPDATED);
      this.resolveGetTasksPromises();

      const newTasksAvailable = newFlowNodeInstanceIds.some((id) => !this.flowNodeInstanceIds.includes(id));
      if (newTasksAvailable) {
        const userMetadataResult = await this.engineService.userMetadataStorage.query('portal:flowNodeInstance:seen', {
          flowNodeInstanceScope: newFlowNodeInstanceIds,
        });
        const newTasks = this.tasks
          .filter((task) => !this.flowNodeInstanceIds.includes(task.flowNodeInstanceId))
          .filter(
            (task) =>
              !userMetadataResult.metadata.some(
                (metadata) => metadata.flowNodeInstanceScope === task.flowNodeInstanceId
              )
          );
        if (newTasks.length) {
          this.emit(EVENT_NEW_TASKS, [newTasks]);
        }
      }

      this.flowNodeInstanceIds = newFlowNodeInstanceIds;
    } catch (error) {
      this.errorOnUpdateTasks = error;
      console.warn(error);
      this.rejectGetTasksPromise(error);
    } finally {
      this.requestInProgress = false;
      if (this.requestRequired) {
        this.requestRequired = false;
        if (this.requestTimeout) {
          window.clearTimeout(this.requestTimeout);
        }
        this.requestTimeout = window.setTimeout(async () => {
          this.requestTimeout = undefined;
          await this.updateTasksIfNecessary(...this.requestRequiredParams);
        }, 1000);
      }
    }
  }

  private resolveGetTasksPromises(): void {
    this.resolveGetTasks.forEach((resolve) => resolve(this.tasks));
  }

  private rejectGetTasksPromise(error?: any): void {
    this.rejectGetTasks.forEach((reject) => reject(error));
  }
}

function arrayEquals(a: Array<any>, b: Array<any>): boolean {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}
