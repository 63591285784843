import { Trans, useTranslation } from 'react-i18next';
import { ForbiddenError, isEngineError } from '@atlas-engine/atlas_engine_sdk';

import { AuthorityUnreachableError, ConfigurationError, EngineUnreachableError } from '../../lib/index';
import Alert from './Alert';

type ErrorRendererProps = {
  error: Error;
};

export function ErrorRenderer(props: ErrorRendererProps): JSX.Element {
  const { t } = useTranslation();
  const error = props.error;

  if (isEngineError(error)) {
    if (error instanceof ForbiddenError && error?.additionalInformation?.claim) {
      const missingClaim = error?.additionalInformation?.claim;

      return (
        <Alert variant="danger">
          <Alert.Heading>{t('ErrorRenderer.NoPermissions')}</Alert.Heading>
          <Alert.Body>
            <Trans
              i18nKey="ErrorRenderer.MissingClaim"
              values={{ missingClaim: missingClaim }}
              components={{ code: <code /> }}
            />
          </Alert.Body>
        </Alert>
      );
    }

    if (error instanceof AuthorityUnreachableError) {
      return (
        <Alert variant="danger">
          <Alert.Heading>{t('ErrorRenderer.AuthorityUnreachableError')}</Alert.Heading>
          <Alert.Body>
            <pre>{error.message.toString()}</pre>
          </Alert.Body>
        </Alert>
      );
    }

    if (error instanceof EngineUnreachableError) {
      return (
        <Alert variant="danger">
          <Alert.Heading>{t('ErrorRenderer.EngineUnreachableError')}</Alert.Heading>
          <Alert.Body>
            <pre>{error.message.toString()}</pre>
          </Alert.Body>
        </Alert>
      );
    }

    return (
      <Alert variant="danger">
        <Alert.Heading>
          {error.name}: {error.message}
        </Alert.Heading>
        <Alert.Body>
          <pre>{JSON.stringify(error, null, '  ')}</pre>
        </Alert.Body>
      </Alert>
    );
  }

  if (error instanceof ConfigurationError) {
    return (
      <Alert variant="danger">
        <Alert.Heading>{t('ErrorRenderer.ConfigurationError')}</Alert.Heading>
        <Alert.Body>
          <pre>{error.message.toString()}</pre>
        </Alert.Body>
      </Alert>
    );
  }

  return (
    <Alert variant="danger">
      <Alert.Heading>{t('ErrorRenderer.UnknownError')}</Alert.Heading>
      <Alert.Body>
        <pre>{error.message.toString()}</pre>
      </Alert.Body>
    </Alert>
  );
}
