import { Component, createRef, Fragment, RefObject } from 'react';
import { Link, Navigate as Redirect } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataModels, Subscription } from '@atlas-engine/atlas_engine_sdk';

import { AnyTaskType, EngineService } from '../../../lib/index';

import { CorrelationFlashMessage } from './CorrelationFlashMessage';

import { ErrorRenderer } from '../../components/ErrorRenderer';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { GenericViewProps } from '../../GenericViewProps';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import LoadingSpinner from '../../components/LoadingSpinner';

type CorrelationTrackerViewState = {
  tasks: Array<AnyTaskType>;
  correlation: DataModels.Correlation.Correlation | null;
  correlationName: string | null;
  isLoading: boolean;
  loadingError: Error | null;
  progressViewTransition: boolean;
  processInstance: DataModels.ProcessInstances.ProcessInstance | null;
};

type CorrelationTrackerViewProps = {
  engineService: EngineService;
  correlationId: string;
  autoNavigate: boolean;
  processInstanceId?: string;
  loadingSpinnerActive: boolean;
  stateAction?: string;
} & WithTranslation &
  GenericViewProps;

class CorrelationTrackerView extends Component<CorrelationTrackerViewProps, CorrelationTrackerViewState> {
  public state: CorrelationTrackerViewState = {
    tasks: [],
    correlation: null,
    correlationName: null,
    isLoading: true,
    loadingError: null,
    processInstance: null,
    progressViewTransition: true,
  };

  private correlationChangedSubscriptions: Array<Subscription> = [];
  private correlationProgressSubscriptions: Array<Subscription> = [];

  private loadingSpinnerRef: RefObject<any> = createRef();
  private showLoadingSpinnerOnInitialRender;

  constructor(props: CorrelationTrackerViewProps) {
    super(props);

    this.showLoadingSpinnerOnInitialRender =
      this.props.loadingSpinnerActive || this.props.loadingSpinnerActiveOnInitialAccess;
  }

  public async componentDidMount(): Promise<void> {
    this.correlationProgressSubscriptions = await this.props.engineService.onCorrelationProgress(
      this.props.correlationId,
      async () => {
        await this.loadCorrelation();
        await this.loadTasksForCorrelation();
      }
    );

    this.correlationChangedSubscriptions = await this.props.engineService.onCorrelationStateChanged(
      this.props.correlationId,
      this.handleCorrelationUpdated
    );

    await this.loadData();
  }

  public async componentWillUnmount(): Promise<void> {
    await this.props.engineService.removeSubscriptions(this.correlationProgressSubscriptions);
    await this.props.engineService.removeSubscriptions(this.correlationChangedSubscriptions);
  }

  public render(): JSX.Element {
    const unknownCorrelationName = this.props.t('CorrelationTracker.CorrelationNameUnknown');
    const correlationName = this.state.correlationName ?? unknownCorrelationName;

    const loadingSpinnerComponent = (
      <DelayedRenderer timeoutInMs={this.showLoadingSpinnerOnInitialRender ? 0 : undefined}>
        <LoadingSpinner htmlRef={this.loadingSpinnerRef} style={{ gridArea: 'content' }} />
      </DelayedRenderer>
    );

    return (
      <Layout>
        {(this.state.isLoading || !this.state.correlation || this.state.progressViewTransition) &&
          !this.state.loadingError &&
          loadingSpinnerComponent}
        <LayoutHeader logo={this.props.logo} />
        <LayoutContent>
          <div
            className={`correlation-tracker-view correlation-tracker-view--${correlationName
              .trim()
              .replaceAll(' ', '-')}`}
          >
            {this.renderContent()}
          </div>
        </LayoutContent>
      </Layout>
    );
  }

  private renderContent(): JSX.Element | null {
    if (this.state.loadingError) {
      return <ErrorRenderer error={this.state.loadingError} />;
    }

    const processInstanceIsFinished =
      this.state.processInstance?.state === DataModels.ProcessInstances.ProcessInstanceState.finished;

    const processInstanceReturnToStartDialogMetadata = this.state.processInstance?.metadata?.returnToStartDialog;
    if (processInstanceIsFinished && processInstanceReturnToStartDialogMetadata) {
      const parsedUrl = new URL(`${window.location.origin}/startdialog/${processInstanceReturnToStartDialogMetadata}`);

      return (
        <Redirect
          to={{
            pathname: parsedUrl.pathname,
            search: parsedUrl.searchParams.toString(),
          }}
          state={{ loadingSpinnerActive: this.loadingSpinnerRef.current != null }}
          replace={true}
        />
      );
    }

    if (this.state.progressViewTransition) {
      const task = this.state.tasks[0];
      if (task) {
        return (
          <Redirect
            to={{
              pathname: `/task/${task.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`,
            }}
            state={{
              loadingSpinnerActive: this.loadingSpinnerRef.current != null,
              progressViewTransition: this.state.progressViewTransition,
              processInstance: this.state.correlation?.processInstances?.find(
                (instance) => instance.processInstanceId === task.processInstanceId
              ),
              ...(this.props.stateAction === 'processStarted' && {
                action: this.props.stateAction,
                correlationId: this.props.correlationId,
              }),
            }}
            replace={true}
          />
        );
      }
    }

    const autoNavigateToOnlyTask = this.state.tasks.length === 1 && this.props.autoNavigate;
    if (autoNavigateToOnlyTask) {
      const task = this.state.tasks[0];
      return (
        <Redirect
          to={{
            pathname: `/task/${task.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`,
          }}
          state={{
            loadingSpinnerActive: this.loadingSpinnerRef.current != null,
            processInstance: this.state.correlation?.processInstances?.find(
              (instance) => instance.processInstanceId === task.processInstanceId
            ),
          }}
          replace={true}
        />
      );
    }

    const correlationIsFinished = this.state.correlation?.processInstances?.every(
      (e) => e.state === DataModels.ProcessInstances.ProcessInstanceState.finished
    );

    if (correlationIsFinished) {
      const returnToStartDialog = this.state.correlation?.metadata?.returnToStartDialog;

      if (returnToStartDialog) {
        const parsedUrl = new URL(`${window.location.origin}/startdialog/${returnToStartDialog}`);
        parsedUrl.searchParams.set('lastActiveCorrelation', `${this.state.correlation?.correlationId}`);

        return (
          <Redirect
            to={{
              pathname: parsedUrl.pathname,
              search: parsedUrl.searchParams.toString(),
            }}
            state={{ loadingSpinnerActive: this.loadingSpinnerRef.current != null }}
            replace={true}
          />
        );
      }

      const searchParams = new URLSearchParams(`lastActiveCorrelation=${this.state.correlation?.correlationId}`);

      return (
        <Redirect
          to={{
            pathname: '/',
            search: searchParams.toString(),
          }}
          state={{ loadingSpinnerActive: this.loadingSpinnerRef.current != null }}
          replace={true}
        />
      );
    }

    const header = this.state.correlationName ?? this.props.t('CorrelationTracker.CorrelationNameUnknown');
    const description = this.props.t('CorrelationTracker.Description', { defaultValue: '' });

    if (this.state.isLoading || !this.state.correlation || this.state.progressViewTransition) {
      return null;
    }

    return (
      <Fragment>
        <Fragment>
          <DelayedRenderer>
            <div className="correlation-tracker-view__title flex justify-center mb-8">
              <div className="text-center max-w-lg">
                {header && <h2 className="mt-2 text-lg font-medium text-[color:var(--text-color)]">{header}</h2>}
                {description && <p className="mt-1 text-sm text-[color:var(--text-brightest-color)]">{description}</p>}
              </div>
            </div>

            <CorrelationFlashMessage correlation={this.state.correlation} tasks={this.state.tasks} />

            <div className="correlation-tracker-view__content overflow-auto">
              {this.state.tasks.length > 0 && (
                <div className="overflow-hidden mt-8 px-px pt-px pb-8">
                  <div className="overflow-auto shadow shadow-[color:var(--shadow-color)] ring-1 ring-[color:rgb(var(--outline-color)/var(--tw-ring-opacity))] ring-opacity-5 md:rounded-lg">
                    <table className="correlation-tracker-view__table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="inline-flex">
                              {this.props.t('CorrelationTracker.TableHeader.Process')}
                            </span>
                          </th>
                          <th scope="col">
                            <span className="inline-flex">{this.props.t('CorrelationTracker.TableHeader.Task')}</span>
                          </th>
                          <th scope="col">
                            <span className="sr-only">{this.props.t('CorrelationTracker.Continue')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tasks.map((task) => {
                          const processInstancePath = this.getProcessInstancePathStringForTask(
                            task,
                            this.state.correlation
                          );

                          return (
                            <tr key={task.flowNodeInstanceId}>
                              <td className="font-medium text-[color:var(--text-color)]">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-[color:var(--text-color)]">
                                      {processInstancePath ??
                                        this.props.t('CorrelationTracker.ProcessInstancePathUnknown')}
                                    </div>
                                    <div className="text-[color:var(--text-brightest-color)]">
                                      {this.state.correlation?.metadata?.description}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="text-[color:var(--text-brightest-color)] ">
                                {task.flowNodeName ?? processInstancePath}
                              </td>
                              <td className="text-right font-medium">
                                <Link
                                  to={{
                                    pathname: `/task/${this.state.correlation?.correlationId}/${task.processInstanceId}/${task.flowNodeInstanceId}`,
                                  }}
                                  state={{
                                    task: task,
                                    processInstance: this.state.correlation?.processInstances?.find(
                                      (instance) => instance.processInstanceId === task.processInstanceId
                                    ),
                                  }}
                                  className="text-[color:var(--link-color)] hover:text-[color:var(--link-hover-color)]"
                                >
                                  {this.props.t('CorrelationTracker.Continue')}
                                  <span className="sr-only">, {task.flowNodeName ?? processInstancePath}</span>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </DelayedRenderer>
        </Fragment>
      </Fragment>
    );
  }

  private async loadData(): Promise<void> {
    try {
      await Promise.all([this.loadCorrelation(), this.loadTasksForCorrelation()]);

      this.setState({ loadingError: null, isLoading: false });
    } catch (error) {
      this.setState({ loadingError: error, isLoading: false });
    }
  }

  private async loadCorrelation(): Promise<void> {
    const correlation = await this.props.engineService.getCorrelation(this.props.correlationId);
    this.handleCorrelationUpdated(correlation);
  }

  private async loadTasksForCorrelation(): Promise<void> {
    const tasks = await this.props.engineService.getTasksInCorrelation(this.props.correlationId);
    this.setState({ tasks: tasks });
  }

  private handleCorrelationUpdated = (correlation: DataModels.Correlation.Correlation): void => {
    const processInstance = correlation?.processInstances?.find(
      (instance) => instance.processInstanceId === this.props.processInstanceId
    );

    const progressViewTransition =
      processInstance?.metadata?.progressView?.toLowerCase() !== 'correlation' && this.props.autoNavigate;

    this.setState({
      correlation: correlation,
      correlationName:
        correlation.processInstances?.find((instance) => !instance.parentProcessInstanceId)?.processModelName ??
        this.props.t('CorrelationTracker.ProcessDefinitionNameNotSet'),
      processInstance: processInstance || null,
      progressViewTransition: progressViewTransition,
    });
  };

  private getProcessInstancePathStringForTask = (
    task: AnyTaskType,
    correlation: DataModels.Correlation.Correlation | null
  ): string | null => {
    if (!task.processInstanceId || !correlation) {
      return null;
    }

    return this.getProcessInstancePath(task.processInstanceId, correlation)
      .map((processInstance) => processInstance.processModelName)
      .join(' / ');
  };

  /**
   * Returns a sorted list of process instances that take up the execution path in a correlation.
   *
   * For example:
   *  - 'Foo' starts 'Bar' and 'Baz (1)'
   *  - 'Bar' starts 'Quz'
   *  - 'Quz' starts 'Baz (2)'
   *
   *  The path for 'Baz (2)' is 'Foo' > 'Bar' > 'Quz' > 'Baz (2)'.
   *
   * The last element of the array is always the searched process instance.
   *
   * @param searchedProcessInstanceId
   * @param correlation
   */
  private getProcessInstancePath(
    searchedProcessInstanceId: string,
    correlation: DataModels.Correlation.Correlation
  ): Array<DataModels.ProcessInstances.ProcessInstance> {
    const path = [];

    let currentSearchedParent = searchedProcessInstanceId;
    let currentProcess = null;

    do {
      currentProcess = correlation.processInstances?.find(
        (instance) => currentSearchedParent === instance.processInstanceId
      );
      if (!currentProcess) {
        break;
      }

      currentSearchedParent = currentProcess.parentProcessInstanceId || '';
      path.unshift(currentProcess);
    } while (currentProcess);

    return path;
  }
}

export const TranslatedCorrelationTrackerView = withTranslation()(CorrelationTrackerView);
