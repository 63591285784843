export type ParsedVersion = {
  baseString: string;
  releaseChannelName: string;
  releaseChannelNumber?: number;
  major: number;
  minor: number;
  patch: number;
};

type Semver = {
  major: number;
  minor: number;
  patch: number;
};

export function parseVersion(version: string): ParsedVersion {
  const isRegularRelease = !version.includes('-');
  if (isRegularRelease) {
    return {
      baseString: version,
      releaseChannelName: 'stable',
      ...getVersionIdentifiers(version),
    };
  }

  const parts = version.split('-');
  const baseString = parts[0];
  const releaseChannelString = parts[1];

  const releaseChannelParts = releaseChannelString.split('.');
  const releaseChannelName = releaseChannelParts[0];
  const releaseChannelNumber = parseInt(releaseChannelParts[1]);
  const versionIdentifiers = getVersionIdentifiers(baseString);

  return {
    baseString: baseString,
    releaseChannelName: releaseChannelName,
    releaseChannelNumber: releaseChannelNumber,
    ...versionIdentifiers,
  };
}

export function versionIsGreaterOrEqual(version: ParsedVersion, comparer: ParsedVersion): boolean {
  const versionMatchesExactly =
    version.major === comparer.major && version.minor === comparer.minor && version.patch === comparer.patch;

  // Only necessary, if the required version is pinned to a specific pre-release and the version to verify is not stable.
  if (versionMatchesExactly && version.releaseChannelName != 'stable' && comparer.releaseChannelName != 'stable') {
    const releaseChannelMatches = version.releaseChannelName === comparer.releaseChannelName;
    const releaseChannelVersionMatches =
      // Accounts for tags versions like "13.3.0-feature~sfdgsfadgsdfg"
      !isNaN(parseInt(`${version.releaseChannelNumber}`)) &&
      (version.releaseChannelNumber as number) >= (comparer.releaseChannelNumber as number);

    return versionMatchesExactly && releaseChannelMatches && releaseChannelVersionMatches;
  }

  const isGreaterMajor = version.major > comparer.major;
  const isGreaterMinor = version.major === comparer.major && version.minor > comparer.minor;
  const isGreaterOrEqualPatch =
    version.major === comparer.major && version.minor === comparer.minor && version.patch >= comparer.patch;

  return isGreaterMajor || isGreaterMinor || isGreaterOrEqualPatch;
}

function getVersionIdentifiers(version: string): Semver {
  const versionParts = version.split('.');

  return {
    major: parseInt(versionParts[0]),
    minor: parseInt(versionParts[1]),
    patch: parseInt(versionParts[2]),
  };
}
