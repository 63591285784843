import { PropsWithChildren, useEffect, useState } from 'react';
import { AppHeader } from './components/AppHeader';

type LayoutProps = PropsWithChildren<{ id?: string }>;
type LayoutHeaderProps = Parameters<typeof AppHeader>[0];
type LayoutContentProps = any;

export function Layout(props: LayoutProps): JSX.Element {
  const [classNameString, setClassNameString] = useState('');

  useEffect(() => {
    const layoutContent =
      typeof (props.children as any)?.find === 'function'
        ? (props.children as any)?.find((child: any) => child?.type?.name === LayoutContent.name)
        : [];
    const contentChildren = Array.isArray(layoutContent?.props?.children)
      ? layoutContent?.props?.children
      : [layoutContent?.props?.children];
    const classNames = contentChildren.map((child: any) => {
      const classes = child?.props?.className ?? '';
      return classes.split(' ');
    }) as Array<string>;

    if (props.id) {
      classNames.push(...props.id.split(' '));
    }

    const flattenClassNames = classNames.flat().filter((entry: any) => entry != null && entry !== '');
    const mappedClassNames = flattenClassNames.map((className: any) => `app-layout--${className}`);

    setClassNameString(mappedClassNames.join(' '));
  }, [props.children]);

  return <div className={`app-layout ${classNameString}`}>{props.children}</div>;
}

export function LayoutHeader(props: LayoutHeaderProps): JSX.Element {
  return (
    <div className="app-layout__app-header">
      <AppHeader {...props} />
    </div>
  );
}

export function LayoutContent(props: LayoutContentProps): JSX.Element {
  return <div className="app-layout__content">{props.children}</div>;
}
