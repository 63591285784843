import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function LoadingSpinner(props: {
  htmlRef?: React.LegacyRef<any>;
  style?: React.CSSProperties;
  sublabel?: string | JSX.Element;
  backdrop?: boolean;
}): JSX.Element {
  return (
    <>
      {props.backdrop && (
        <Transition
          as={Fragment}
          show={true}
          appear={true}
          enter="ease-out duration-200 transition-opacity"
          enterFrom="opacity-0"
          enterTo="opacity-50"
          leave="ease-in duration-200"
          leaveFrom="opacity-50"
          leaveTo="opacity-0"
        >
          <span className="loading-spinner__backdrop" style={props.style}></span>
        </Transition>
      )}
      <Transition
        as={Fragment}
        show={true}
        appear={true}
        enter="ease-out duration-75 transition-opacity"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <span ref={props.htmlRef} className="loading-spinner" style={props.style}>
          {props.sublabel && <p className="loading-spinner__sublabel">{props.sublabel}</p>}
        </span>
      </Transition>
    </>
  );
}
