import { useContext } from 'react';
import { TranslatedCorrelationTrackerView } from './CorrelationTrackerView';

import { EngineContext, useLocation } from '../../../lib/index';
import { GenericViewProps } from '../../GenericViewProps';
import { useParams, useSearchParams } from 'react-router-dom';

type CorrelationTrackerViewParameters = {
  correlationId: string;
};

export function CorrelationTrackerViewWithRouter(genericViewProps: GenericViewProps): JSX.Element {
  const engineService = useContext(EngineContext);
  const { correlationId } = useParams<CorrelationTrackerViewParameters>();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (!correlationId) {
    throw new Error('CorrelationId not provided');
  }
  if (!engineService) {
    throw new Error('Engine Service missing in app context');
  }

  const autoNavigate = searchParams.get('autoNavigate') !== 'false';

  return (
    <TranslatedCorrelationTrackerView
      {...genericViewProps}
      correlationId={correlationId}
      autoNavigate={autoNavigate}
      engineService={engineService}
      processInstanceId={location.state?.processInstanceId}
      loadingSpinnerActive={location.state?.loadingSpinnerActive === true}
      stateAction={location.state?.action}
    />
  );
}
