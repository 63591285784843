import React from 'react';
import { useNavigate, useNavigationType, useParams, useSearchParams } from 'react-router-dom';
import type { RouteComponentProps, Location } from './InternalTypes';
import { useLocation } from './useLocation';

export function withRouter<P extends RouteComponentProps>(Component: React.ComponentType<P>) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const navigationType = useNavigationType();
    const searchParams = useSearchParams();

    return <Component {...props} router={{ location, navigate, navigationType, params, searchParams }} />;
  }

  return ComponentWithRouterProp;
}
