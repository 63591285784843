import { Component, createRef, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Base64 } from 'js-base64';

import {
  DisplayStartDialogMessage,
  StartDialogConfig,
  StartDialogMessageType,
  StartDialogsConfig,
  UpdateIdentityStartDialogMessage,
  UpdateLanguageStartDialogMessage,
  isCloseStartDialogMessage,
  isLogoutStartDialogMessage,
  isOpenStartDialogMessage,
  isStartProcessStartDialogMessage,
  isUpdateLanguageStartDialogMessage,
  isResolveDisplayStartDialogMessage,
  isResolveInitializeStartDialogMessage,
  InitializeStartDialogMessage,
  isTranscendentErrorStartDialogMessage,
  StartDialogPromiseRejectionEvent,
  StartDialogErrorEvent,
} from '@atlas-engine-contrib/atlas-ui_contracts';

import {
  IAuthService,
  IdentityWithEmailAndName,
  getAllowedStartDialogs,
  LocationState,
  useLocation,
} from '../../../lib';
import { GenericViewProps } from '../../GenericViewProps';
import { Layout, LayoutContent, LayoutHeader } from '../../Layout';
import { useIdentity } from '../../context';
import { ErrorRenderer } from '../../components/ErrorRenderer';
import type { LanguageService } from '../../../lib/LanguageService';
import LoadingSpinner from '../../components/LoadingSpinner';
import { DelayedRenderer } from '../../components/DelayedRenderer';
import ProductInfo from '../../../generatedProductInfo';
import Alert from '../../components/Alert';

type StartDialogViewProps = {
  startDialogId: string;
  config: StartDialogConfig;
  navigate: (url: string) => void;
  identity?: IdentityWithEmailAndName;
  languageService: LanguageService;
  logout: () => void;
  payload?: Record<string, unknown>;
  iFrameStyle?: React.CSSProperties;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type StartDialogViewWithRouterProps = {
  authService: IAuthService;
  languageService: LanguageService;
  config: StartDialogsConfig;
  startDialogId?: string;
  payload?: string;
} & GenericViewProps;

type StartDialogHomepageProps = Omit<StartDialogViewWithRouterProps, 'payload'>;

class StartDialogView extends Component<StartDialogViewProps, any> {
  private iFrameRef = createRef<HTMLIFrameElement>();
  private handleMessageReceivedBound = this.handleMessageReceived.bind(this);
  private handleIFrameLoadedBound = this.handleIFrameLoaded.bind(this);
  private handleLanguageChangedBound = this.handleLanguageChanged.bind(this);

  private isInitialized = false;

  /**
   * could be used in the future
   */
  private portalSdkVersion?: string;
  private emitInitializeMessageInterval?: number;

  constructor(props: StartDialogViewProps) {
    super(props);
  }

  public componentDidMount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.addEventListener('load', this.handleIFrameLoadedBound);
    }

    window.addEventListener('message', this.handleMessageReceivedBound);
    this.props.languageService.onLanguageChanged(this.handleLanguageChangedBound);
  }

  public componentWillUnmount(): void {
    const iframe = this.iFrameRef.current;
    if (iframe) {
      iframe.removeEventListener('load', this.handleIFrameLoadedBound);
    }

    window.removeEventListener('message', this.handleMessageReceivedBound);
    this.props.languageService.removeOnLanguageChanged(this.handleLanguageChangedBound);
    if (this.emitInitializeMessageInterval) {
      window.clearInterval(this.emitInitializeMessageInterval);
    }
  }

  public componentDidUpdate(prevProps: StartDialogViewProps): void {
    if (this.props.identity?.token !== prevProps.identity?.token) {
      this.handleIdentityChanged(this.props.identity);
    }
  }

  public render(): JSX.Element {
    const iframeStyle = this.props.iFrameStyle ?? {};

    return (
      <Fragment>
        <iframe
          className="start-dialog-view__iframe"
          ref={this.iFrameRef}
          title={this.props.config.title}
          src={this.props.config.url}
          frameBorder="0"
          style={{ ...iframeStyle }}
          allow={`camera ${this.props.config.url}; microphone ${this.props.config.url};`}
          data-start-dialog-id={this.props.startDialogId}
        />
      </Fragment>
    );
  }

  private handleLanguageChanged(language: string): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow || !this.isInitialized) {
      return;
    }

    const message: UpdateLanguageStartDialogMessage = {
      type: StartDialogMessageType.UpdateLanguage,
      currentLanguage: language,
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private handleIFrameLoaded(): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow) {
      return;
    }

    const message: InitializeStartDialogMessage = {
      type: StartDialogMessageType.Initialize,
      version: ProductInfo.version.frontend,
      portalLocation: JSON.parse(JSON.stringify(window.location)),
    };

    const sendInitialize = () => iFrame?.contentWindow?.postMessage(message, this.getIFrameOrigin());
    sendInitialize();
    this.emitInitializeMessageInterval = window.setInterval(sendInitialize, 100);
  }

  private sendDisplayStartDialog(): void {
    const message: DisplayStartDialogMessage = {
      type: StartDialogMessageType.DisplayStartDialog,
      configuration: this.props.config,
      identity: this.props.identity as IdentityWithEmailAndName,
      payload: this.props.payload,
      currentLanguage: this.props.languageService.getCurrentLanguage(),
      languages: this.props.languageService.getLanguages(),
    };

    this.iFrameRef.current?.contentWindow?.postMessage(message, this.getIFrameOrigin());
  }

  private handleMessageReceived(event: MessageEvent): void {
    const message = event.data;

    if (isCloseStartDialogMessage(message)) {
      this.props.navigate('/');
    } else if (isStartProcessStartDialogMessage(message)) {
      const returnToStartDialogPayload = { returnToStartDialog: this.props.startDialogId };
      const payload = Object.assign(returnToStartDialogPayload, message.payload);
      const encodedPayload = Base64.encode(JSON.stringify(payload), true);

      const pathname = `/start/${message.processModelId}/${encodedPayload}`;
      const url = new URL(pathname, window.location.origin);
      if (message.startEventId) {
        url.searchParams.append('startEventId', message.startEventId);
      }
      if (message.correlationId) {
        url.searchParams.append('correlationId', message.correlationId);
      }

      this.props.navigate(url.href.replace(url.origin, ''));
    } else if (isOpenStartDialogMessage(message)) {
      const encodedPayload =
        typeof message.payload === 'object' && message.payload != null
          ? Base64.encode(JSON.stringify(message.payload), true)
          : undefined;

      const pathname = `/startdialog/${message.startDialogId}/${encodedPayload ?? ''}`;
      const url = new URL(pathname, window.location.origin);

      this.props.navigate(url.href.replace(url.origin, ''));
    } else if (isUpdateLanguageStartDialogMessage(message)) {
      this.props.languageService
        .setLanguage(message.currentLanguage)
        .then(() => {
          this.sendResolveOrRejectSetLanguageMessage();
        })
        .catch((error) => {
          this.sendResolveOrRejectSetLanguageMessage(error);
        });
    } else if (isLogoutStartDialogMessage(message)) {
      this.props.logout();
    } else if (isResolveDisplayStartDialogMessage(message)) {
      this.props.setIsLoading(false);
    } else if (isResolveInitializeStartDialogMessage(message)) {
      window.clearInterval(this.emitInitializeMessageInterval);
      this.portalSdkVersion = message.version;
      this.isInitialized = true;
      this.sendDisplayStartDialog();
    } else if (isTranscendentErrorStartDialogMessage(message)) {
      let eventToDispatch: PromiseRejectionEvent | ErrorEvent;

      if (message.event.type === 'unhandledrejection') {
        const { reason, type } = message.event as StartDialogPromiseRejectionEvent;
        const reasonForEvent = new Error(reason.message);
        Object.assign(reasonForEvent, reason);

        const rejectedPromise = Promise.reject(reasonForEvent).catch(() => void 0);
        eventToDispatch = new PromiseRejectionEvent(type, {
          reason: reasonForEvent,
          promise: rejectedPromise,
        });
      } else if (message.event.type === 'error') {
        const { type, lineno, colno, error, message: errorMessage, filename } = message.event as StartDialogErrorEvent;
        const errorForEvent = new Error(error.message);
        Object.assign(errorForEvent, error);

        eventToDispatch = new ErrorEvent(type, {
          lineno: lineno,
          colno: colno,
          error: errorForEvent,
          message: errorMessage,
          filename: filename,
        });
      } else {
        return;
      }

      Object.defineProperty(eventToDispatch, 'additionalInformation', {
        value: {
          startDialogId: this.props.startDialogId,
          portalSdkVersion: message.version,
          startDialogPayload: this.props.payload,
          startDialogConfig: this.props.config,
        },
        enumerable: true,
      });

      window.dispatchEvent(eventToDispatch);
    }
  }

  private sendResolveOrRejectSetLanguageMessage(error?: any): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow) {
      return;
    }

    const message = {
      type: error != null ? StartDialogMessageType.ErrorOnSetLanguage : StartDialogMessageType.ResolveSetLanguage,
      error: {
        name: error?.name,
        message: error?.message,
        stack: error?.stack,
      },
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private handleIdentityChanged(newIdentity?: IdentityWithEmailAndName): void {
    const iFrame = this.iFrameRef.current;
    if (!iFrame || !iFrame.contentWindow || !this.isInitialized) {
      return;
    }

    const message: UpdateIdentityStartDialogMessage = {
      type: StartDialogMessageType.UpdateIdentity,
      identity: newIdentity,
    };

    iFrame.contentWindow.postMessage(message, this.getIFrameOrigin());
  }

  private getIFrameOrigin(): string {
    return new URL(this.props.config.url).origin;
  }
}

export function StartDialogViewWithRouter(props: StartDialogViewWithRouterProps): JSX.Element | null {
  const { startDialogId, payload: encodedPayload } = props;
  const push = useNavigate();
  const { identity } = useIdentity();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [payloadError, setPayloadError] = useState<Error | undefined>();
  const [decodedPayload, setDecodedPayload] = useState<Record<string, unknown> | undefined>();

  const showLoadingSpinnerOnInitialRender =
    state?.loadingSpinnerActive === true || props.loadingSpinnerActiveOnInitialAccess;

  const startDialogConfig = props.config[startDialogId ?? ''];

  useEffect(() => {
    if (encodedPayload) {
      try {
        const payload = JSON.parse(Base64.decode(encodedPayload));
        setDecodedPayload(payload);
      } catch (decodeError) {
        setPayloadError(decodeError);
      }
    }
  }, [encodedPayload]);

  const content = (): JSX.Element | null => {
    if (payloadError) {
      return (
        <LayoutContent>
          <ErrorRenderer error={payloadError} />
        </LayoutContent>
      );
    }

    if (!startDialogId || !startDialogConfig) {
      return (
        <LayoutContent>
          <Alert variant="danger">
            <Alert.Body>
              <Trans
                i18nKey="StartDialogView.StartDialogNotFound"
                values={{
                  startDialogId: startDialogId,
                }}
                components={{
                  code: <code />,
                }}
              ></Trans>
            </Alert.Body>
          </Alert>
        </LayoutContent>
      );
    }

    return (
      <StartDialogView
        config={startDialogConfig}
        identity={identity || undefined}
        iFrameStyle={{ visibility: isLoading ? 'hidden' : 'visible' }}
        languageService={props.languageService}
        logout={() => props.authService.logout()}
        navigate={push}
        payload={decodedPayload}
        setIsLoading={setIsLoading}
        startDialogId={startDialogId}
      />
    );
  };

  const loadingSpinnerComponent = (
    <DelayedRenderer timeoutInMs={showLoadingSpinnerOnInitialRender ? 0 : undefined}>
      <LoadingSpinner style={{ gridArea: 'content' }} />
    </DelayedRenderer>
  );

  return (
    <Layout id={`start-dialog-view start-dialog-view--${startDialogId?.trim().replaceAll(' ', '-')}`}>
      <LayoutHeader logo={props.logo} />
      {isLoading && !payloadError! && startDialogId && startDialogConfig && loadingSpinnerComponent}
      {content()}
    </Layout>
  );
}

export function StartDialogHomepage(props: StartDialogHomepageProps): JSX.Element {
  const { startDialogId } = props;
  const push = useNavigate();
  const { identity } = useIdentity();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [startDialogConfigs, setStartDialogConfigs] = useState<StartDialogsConfig | null>(null);

  const showLoadingSpinnerOnInitialRender =
    state?.loadingSpinnerActive === true || props.loadingSpinnerActiveOnInitialAccess;

  useEffect(() => {
    getAllowedStartDialogs(props.config, props.authService).then((startDialogs) => setStartDialogConfigs(startDialogs));
  }, [props.config, identity]);

  const startDialogConfig = startDialogConfigs ? startDialogConfigs[startDialogId ?? ''] : null;
  const startDialogIdentifier = `start-dialog-view--${startDialogId?.trim().replaceAll(' ', '-')}`;

  const renderContent = (): JSX.Element | null => {
    if (!startDialogConfigs) {
      return null;
    }

    if (!startDialogId || !startDialogConfig) {
      return (
        <LayoutContent>
          <Alert variant="danger">
            <Alert.Body>
              <Trans
                i18nKey="StartDialogView.StartDialogNotFound"
                values={{
                  startDialogId: startDialogId,
                }}
                components={{
                  code: <code />,
                }}
              ></Trans>
            </Alert.Body>
          </Alert>
        </LayoutContent>
      );
    }

    const loadingSpinnerComponent = (
      <DelayedRenderer timeoutInMs={showLoadingSpinnerOnInitialRender ? 0 : undefined}>
        <LoadingSpinner style={{ gridArea: 'content' }} />
      </DelayedRenderer>
    );

    return (
      <Fragment>
        {isLoading && loadingSpinnerComponent}
        <StartDialogView
          config={startDialogConfig}
          identity={identity || undefined}
          iFrameStyle={{ visibility: isLoading ? 'hidden' : 'visible' }}
          languageService={props.languageService}
          logout={() => props.authService.logout()}
          navigate={push}
          setIsLoading={setIsLoading}
          startDialogId={startDialogId}
        />
      </Fragment>
    );
  };

  return (
    <Layout id={`start-dialog-view ${startDialogIdentifier}`}>
      <LayoutHeader logo={props.logo} />
      {renderContent()}
    </Layout>
  );
}
