import { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  ProcessModelConfig,
  StartDialogConfig,
  StartDialogsConfig,
  StartableGroupConfig,
} from '@atlas-engine-contrib/atlas-ui_contracts';

import { EngineService } from '../../../lib';
import { StartableGroup } from './StartableGroup';
import { CollectionIcon } from '@heroicons/react/outline';
import Alert from '../Alert';

type StartableListProps = {
  processModels: Array<ProcessModelConfig>;
  startDialogs: StartDialogsConfig;
  startablesOrder?: Array<string>;
  startableGroups?: Array<StartableGroupConfig>;
  engineService: EngineService;
  maxVisibleStartables?: number;
  showGroups?: boolean;
} & WithTranslation;

type ProcessModelStartable = {
  type: 'processModel';
  config: ProcessModelConfig;
};

type StartDialogStartable = {
  type: 'startDialog';
  config: StartDialogConfig & { id: string };
};

export type Startable = ProcessModelStartable | StartDialogStartable;

type GroupedStartables = { [groupId: string]: Array<Startable> };

type GroupStartablesResult = {
  groups: GroupedStartables;
  ungrouped: Array<Startable>;
};

export class StartableListComponent extends Component<StartableListProps> {
  // "withRouter" breaks TypeScript's support for "defaultProps". That's why "showGroups" is currently marked as optional.
  public static defaultProps = {
    showGroups: true,
  };

  public render(): JSX.Element {
    const { t, maxVisibleStartables, showGroups } = this.props;
    let startables = this.getStartables();

    if (startables.length === 0) {
      return <EmptyState t={t} />;
    }

    const duplicateIds = this.findDuplicateIds(startables);
    let duplicateIdsAlert = null;

    if (duplicateIds.length === 0) {
      startables = startables.sort(this.sortByStartablesOrder.bind(this));
    } else {
      const message = t('StartableList.DuplicateIdsFound', { duplicateIds: duplicateIds.join(', ') });
      duplicateIdsAlert = (
        <Alert variant="danger" className="mb-4">
          <Alert.Body>{message}</Alert.Body>
        </Alert>
      );
    }

    return (
      <Fragment>
        {duplicateIdsAlert}
        <div className="startable-list">{this.renderStartables(startables, showGroups, maxVisibleStartables)}</div>
      </Fragment>
    );
  }

  private renderStartables(
    startables: Array<Startable>,
    showGroups?: boolean,
    maxVisibleStartables?: number
  ): JSX.Element | Array<JSX.Element> {
    let startablesToUse = startables;
    if (maxVisibleStartables != null && maxVisibleStartables > 0) {
      const filteredStartablesCopy = [...startablesToUse];
      startablesToUse = filteredStartablesCopy.splice(0, maxVisibleStartables);
    }

    if (!showGroups) {
      return this.renderUngroupedStartables(startablesToUse);
    }

    const groupStartablesResult = this.groupStartables(startablesToUse);
    const groupedStartableComponents = this.renderGroupedStartables(groupStartablesResult.groups);
    const ungroupedStartableComponents = this.renderUngroupedStartables(groupStartablesResult.ungrouped);

    return groupedStartableComponents.concat(ungroupedStartableComponents);
  }

  private renderGroupedStartables(groupedStartables: GroupedStartables): Array<JSX.Element> {
    if (!this.props.startableGroups) {
      return [];
    }

    return this.props.startableGroups
      .map((group) => {
        const startablesInGroup = groupedStartables[group.id];

        if (!startablesInGroup || startablesInGroup.length === 0) {
          return null;
        }

        return (
          <StartableGroup
            key={group.id}
            engineService={this.props.engineService}
            group={group}
            startables={startablesInGroup}
          />
        );
      })
      .filter(notEmpty);
  }

  private renderUngroupedStartables(startables: Array<Startable>): JSX.Element {
    const group = {
      id: 'ungrouped',
      title: this.props.t('StartableList.Ungrouped', { defaultValue: '' }),
    };

    return (
      <StartableGroup key={group.id} engineService={this.props.engineService} group={group} startables={startables} />
    );
  }

  private getStartables(): Array<Startable> {
    const processModelStartables = this.props.processModels.map((processModelConfig): Startable => {
      return {
        type: 'processModel',
        config: processModelConfig,
      };
    });

    const startDialogStartables = Object.entries(this.props.startDialogs)
      .map(([startDialogId, startDialogConfig]) => {
        return Object.assign({ id: startDialogId }, startDialogConfig);
      })
      .map((startDialogConfig): Startable => {
        return {
          type: 'startDialog',
          config: startDialogConfig,
        };
      });

    return processModelStartables.concat(startDialogStartables);
  }

  private groupStartables(startables: Array<Startable>): GroupStartablesResult {
    const result: GroupStartablesResult = {
      groups: {},
      ungrouped: [],
    };

    for (const startable of startables) {
      if (!startable.config.groupId) {
        result.ungrouped.push(startable);
        continue;
      }

      const groupId = startable.config.groupId;
      const groupIsConfigured = this.props.startableGroups?.some((startableGroup) => startableGroup.id === groupId);

      if (!groupIsConfigured) {
        result.ungrouped.push(startable);
        continue;
      }

      const groupAlreadyExists = Object.prototype.hasOwnProperty.call(result.groups, groupId);

      if (groupAlreadyExists) {
        result.groups[groupId].push(startable);
      } else {
        result.groups[groupId] = [startable];
      }
    }

    return result;
  }

  private findDuplicateIds(startables: Array<Startable>): Array<string> {
    const startableIds = startables.map((startable) => startable.config.id);
    const duplicateStartableIds = startableIds.filter(
      (startableId, index) => startableIds.indexOf(startableId) !== index
    );

    return [...new Set(duplicateStartableIds)];
  }

  private sortByStartablesOrder(firstStartable: Startable, secondStartable: Startable): number {
    const startablesOrder = this.props.startablesOrder ?? [];
    const firstStartableInOrderList = startablesOrder.includes(firstStartable.config.id);
    const secondStartableInOrderList = startablesOrder.includes(secondStartable.config.id);

    if (firstStartableInOrderList && !secondStartableInOrderList) {
      return -1;
    }

    if (!firstStartableInOrderList && secondStartableInOrderList) {
      return 1;
    }

    if (firstStartableInOrderList && secondStartableInOrderList) {
      const firstStartableOrderIndex = startablesOrder.indexOf(firstStartable.config.id);
      const secondStartableOrderIndex = startablesOrder.indexOf(secondStartable.config.id);

      return firstStartableOrderIndex - secondStartableOrderIndex;
    }

    const compareOptions = { usage: 'sort', sensitivity: 'base' }; // ignore case differences
    return firstStartable.config.title.localeCompare(secondStartable.config.title, undefined, compareOptions);
  }
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value != null;
}

function EmptyState(props: any): JSX.Element {
  return (
    <div className="text-center">
      <CollectionIcon className="h-10" />
      <h3 className="mt-2 text-sm font-medium text-[color:var(--text-color)]">
        {props.t('StartableList.EmptyStateTitle')}
      </h3>
      <p className="mt-1 text-sm text-[color:var(--text-brightest-color)]">{props.t('StartableList.EmptyStateBody')}</p>
    </div>
  );
}

export const StartableList = withTranslation()(StartableListComponent);
